import {
    Button,
    FormControl,
    FormErrorMessage,
    RadioGroup,
    VStack
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import Phone from '../../../../components/Icon/Phone'
import useDelivery from '../../../../hooks/useDelivery'
import Client from '../../../../models/client'
import { DeliveryStatus } from '../../../../types/deliveryMode'
import useUpdateClient from '../../hooks/useUpdateClient'
import BoxContent from '../../shared/BoxContent'
import FormInput from '../../shared/FormInput'
import {
    useDeliveryForm,
    useDeliveryHelper
} from '../../store/delivery-form.store'
import RadioLabelPhone from 'pages/DeliveryCustomers/shared/RadioLabelPhone'

export default function PhonesList() {
    const { client, delivery } = useDelivery()
    const { submit, isLoading } = useUpdateClient()
    const [modeAdd, setModeAdd] = useState(false)
    const buttonRef = useRef<HTMLButtonElement>(null)
    const phone = useDeliveryForm(state => state.phone)
    const set = useDeliveryForm(state => state.set)
    const helpers = useDeliveryHelper(state => state)

    async function submitHandler(value: string) {
        if (value === '') return
        const hasPhone = client?.phones
            .map(_phone => _phone.toLowerCase())
            .includes(value.toLowerCase().trim())
        if (hasPhone) return

        await submit({ phones: [...(client as Client).phones, value] })

        setModeAdd(false)
        if (buttonRef.current) {
            buttonRef.current.focus()
        }

        set({ phone: value })
    }

    async function submitEditHandler(value: string, _index: number) {
        if (value === '') return
        const hasPhone = client?.phones
            .map(_phone => _phone.toLowerCase())
            .includes(value.toLowerCase().trim())
        if (hasPhone) return

        const phones = []

        const clientPhones = client?.phones ?? []

        for (let index = 0; index < clientPhones?.length; index++) {
            const element = client?.phones[index]
            if (index === _index) {
                phones.push(value)
            } else {
                phones.push(element ?? '')
            }
        }

        await submit({ phones })
    }

    useEffect(() => {
        helpers.setHelpers({ phone: '' })
    }, [phone])

    return (
        <BoxContent title="Seleccione teléfono">
            <FormControl isInvalid={!!helpers.phone}>
                <RadioGroup
                    value={phone}
                    mb={1}
                    onChange={val => set({ phone: val })}
                    isDisabled={
                        delivery
                            ? delivery.status > 2 ||
                              delivery.status === DeliveryStatus.CANCELED
                            : false
                    }
                >
                    {(client as Client).phones.map((_phone, i) => (
                        <RadioLabelPhone
                            key={_phone}
                            value={_phone}
                            label={_phone}
                            submitEditHandler={submitEditHandler}
                            isLoading={isLoading}
                            index={i}
                        />
                    ))}
                </RadioGroup>
                <FormErrorMessage>{helpers.phone}</FormErrorMessage>
            </FormControl>
            <VStack
                spacing={1}
                alignItems={modeAdd ? 'flex-end' : 'flex-start'}
            >
                {modeAdd && (
                    <FormControl>
                        <FormInput
                            isLoading={isLoading}
                            icon={<Phone />}
                            onSubmit={submitHandler}
                            type="number"
                            placeholder="Escribir nuevo número de contacto"
                        />
                    </FormControl>
                )}

                <Button
                    fontWeight="medium"
                    colorScheme="blue"
                    variant="link"
                    isDisabled={
                        delivery
                            ? delivery.status > 2 ||
                              delivery.status === DeliveryStatus.CANCELED
                            : false
                    }
                    size="sm"
                    ref={buttonRef}
                    onClick={() => {
                        setModeAdd(!modeAdd)
                    }}
                >
                    {modeAdd ? 'Cancelar' : '+ Nuevo teléfono'}
                </Button>
            </VStack>
        </BoxContent>
    )
}
