import React, { FormEvent } from 'react'
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea,
    useId,
    VStack
} from '@chakra-ui/react'
import { AddressFormData } from './types'

interface Props {
    isOpen: boolean
    handleClose: () => void
    helpers: Partial<AddressFormData>
    handleSubmit: (ev: FormEvent<HTMLFormElement>) => void
    address?: string
    reference?: string
    isLoading: boolean
    isEdit?: boolean
    handleInputChange?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
}

export default function AddressModalForm({
    isOpen,
    helpers,
    handleClose,
    handleSubmit,
    isEdit = false,
    ...props
}: Props) {
    const id = useId()

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                if (props.isLoading) return
                handleClose()
            }}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {isEdit ? 'Actualizar dirección' : 'Nueva dirección'}{' '}
                </ModalHeader>
                <ModalBody>
                    <form id={id} onSubmit={handleSubmit} noValidate>
                        <VStack spacing={4}>
                            <FormControl
                                isRequired
                                isInvalid={!!helpers.address}
                            >
                                <FormLabel>Dirección</FormLabel>
                                <Input
                                    autoFocus
                                    name="address"
                                    placeholder="Ingresar dirección"
                                    {...(isEdit && {
                                        value: props.address ?? '',
                                        onChange: props.handleInputChange
                                    })}
                                />
                                <FormErrorMessage>
                                    {helpers.address}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Referencia (Opcional)</FormLabel>
                                <Textarea
                                    name="reference"
                                    placeholder="Ejemplo: a dos cuadras del hospital"
                                    {...(isEdit && {
                                        value: props.reference ?? '',
                                        onChange: props.handleInputChange
                                    })}
                                ></Textarea>
                            </FormControl>
                        </VStack>
                    </form>
                </ModalBody>
                <ModalFooter gap={2}>
                    <Button onClick={handleClose} disabled={props.isLoading}>
                        Cancelar
                    </Button>
                    <Button
                        isLoading={props.isLoading}
                        form={id}
                        type="submit"
                        colorScheme="green"
                    >
                        {isEdit ? 'Actualizar' : 'Guardar'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
