import { Button } from '@chakra-ui/react'
import { FormEvent, useState } from 'react'
import useDelivery from '../../../../hooks/useDelivery'
import val from '../../../../lib/val'
import Client from '../../../../models/client'
import extractDataForm from '../../../../utilities/extractDataForm'
import useUpdateClient from '../../hooks/useUpdateClient'
import { useDeliveryForm } from '../../store/delivery-form.store'
import { AddressFormData } from './types'
import AddressModalForm from './AddressModalForm'

export default function AddressFormModal() {
    const { submit, isLoading } = useUpdateClient()
    const [isOpen, setIsOpen] = useState(false)
    const client = useDelivery().client as Client
    const setForm = useDeliveryForm(state => state.set)
    const [helpers, setHelpers] = useState<Partial<AddressFormData>>({})

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()

        const form = extractDataForm<AddressFormData>(ev.target)
        const v = val<AddressFormData>(form)
        v.address.isEmpty('La dirección es requerida')
        const result = v.runtest()
        if (result) {
            setHelpers(result)
            return
        }
        const currentAddress = client.addresses.find(
            _address =>
                _address[0].toLocaleLowerCase() ===
                form.address.toLowerCase().trim()
        )

        if (currentAddress) {
            await submit({
                addresses: [
                    ...client.addresses,
                    [currentAddress[0], form.reference]
                ]
            })
            setForm({
                address: [currentAddress[0], form.reference]
            })
        } else {
            await submit({
                addresses: [...client.addresses, [form.address, form.reference]]
            })
            setForm({
                address: [form.address, form.reference]
            })
        }

        setIsOpen(false)
    }

    return (
        <>
            <Button
                fontWeight="medium"
                colorScheme="blue"
                variant="link"
                size="sm"
                onClick={() => setIsOpen(true)}
            >
                + Nueva dirección
            </Button>
            <AddressModalForm
                isOpen={isOpen}
                handleClose={() => setIsOpen(false)}
                helpers={helpers}
                isLoading={isLoading}
                handleSubmit={submitHandler}
            />
        </>
    )
}
