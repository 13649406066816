import { Box, Flex, Icon, Image, Radio, Text } from '@chakra-ui/react'
import Pencil from 'components/Icon/Pencil'

export default function RadioLabel({
    value,
    label,
    image,
    description,
    ...props
}: RadioLabelProps) {
    return (
        <label>
            <Flex
                w="full"
                cursor="pointer"
                borderWidth={1}
                rounded="md"
                px={2}
                py={1}
                bg="white"
                justify="space-between"
                alignItems="center"
            >
                <Flex gap={3}>
                    <Radio name="payment" value={value} />
                    <Box>
                        <Text fontSize="sm">{label}</Text>
                        {description && (
                            <Text
                                fontSize="xs"
                                color="blackAlpha.700"
                                fontWeight="light"
                            >
                                {description}
                            </Text>
                        )}
                    </Box>
                </Flex>
                <Flex gap={2} alignItems={'center'}>
                    {image && <Image src={image} alt={label} w="6" />}

                    {props.handleEdit && (
                        <Icon
                            fill="blue.400"
                            as={Pencil}
                            cursor="pointer"
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                props.handleEdit?.()
                            }}
                        />
                    )}
                </Flex>
            </Flex>
        </label>
    )
}

export interface RadioLabelProps {
    value: string
    label: string
    description?: string
    image?: string
    handleEdit?: () => void
}
