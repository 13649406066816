import {
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement
} from '@chakra-ui/react'
import { KeyboardEvent, useState } from 'react'
import ArrowRight from '../../../components/Icon/ArrowRight'
import { FormInputProps } from './types'

export default function FormInput({
    onSubmit,
    icon,
    isLoading,
    ...props
}: FormInputProps) {
    const [value, setValue] = useState((props.value as string) ?? '')

    function keyboardHandler(ev: KeyboardEvent<HTMLInputElement>) {
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            ev.preventDefault()
            onSubmit(value)
        }
    }

    return (
        <InputGroup>
            <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
            <Input
                {...props}
                bg="base-2"
                autoFocus
                borderColor="orange.600"
                value={value}
                onChange={ev => setValue(ev.target.value)}
                onKeyDown={keyboardHandler}
            />
            <InputRightElement>
                <IconButton
                    aria-label="Close"
                    size="sm"
                    isLoading={isLoading}
                    colorScheme="orange"
                    isDisabled={!value}
                    onClick={() => onSubmit(value)}
                    icon={<ArrowRight width={10} height={10} />}
                />
            </InputRightElement>
        </InputGroup>
    )
}
