import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Badge,
    Box,
    Button,
    Icon,
    IconButton,
    useDisclosure,
    useToast
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ArrowRight from '../../../components/Icon/ArrowRight'
import CashIcon from '../../../components/Icon/CashIcon'
import Close from '../../../components/Icon/Close'
import useDelivery from '../../../hooks/useDelivery'
import useGoToPay from '../hooks/useGoToPay'
import useOpenCashbox from '../hooks/useOpenCashbox'
import {
    useDeliveryForm,
    useDeliveryHelper
} from '../store/delivery-form.store'
import { DeliveryStatus } from 'types/deliveryMode'
import getPrinterConfig from 'utilities/getPrinterConfig'
import { post } from 'lib/http'
import Printer from 'components/Icon/Printer'

export default function CloseOrGoToPay() {
    const { setClient, setDelivery, setProducts, delivery } = useDelivery()
    const clear = useDeliveryHelper(state => state.clear)
    const { clear: clearForm } = useDeliveryForm()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef<HTMLButtonElement>(null)
    const { push } = useHistory()
    const { go } = useGoToPay()
    const { isOpen: isOpenCashbox } = useOpenCashbox()
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()

    function yesHandler() {
        setClient(null)
        setDelivery(null)
        setProducts([])
        push('/delivery/customers')
        clear()
        clearForm()
        onClose()
    }

    const handleReprint = async () => {
        const ipConfig = getPrinterConfig()
        if (!ipConfig) {
            toast({
                title: 'Configure primero la impresosa',
                status: 'warning',
                position: 'top'
            })
            return
        }

        if (!delivery) {
            toast({
                title: 'Mo se encontre el delivery',
                status: 'warning',
                position: 'top'
            })
            return
        }

        setIsLoading(true)

        const { error } = await post(`/api/deliveries/${delivery.id}/reprint`, {
            printerId: Number(ipConfig.printer_id)
        })
        setIsLoading(false)

        if (error) {
            toast({
                title: 'No fue reimprimir el delivery',
                status: 'error',
                position: 'top'
            })
            return
        }
    }

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Cancelar
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Está seguro cancelar la order para este cliente?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button
                                ref={cancelRef}
                                onClick={yesHandler}
                                colorScheme="red"
                            >
                                Si, continuar
                            </Button>
                            <Button onClick={onClose} ml={2}>
                                Cancelar
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {!delivery ? (
                <IconButton
                    icon={<Close />}
                    aria-label="Cancelar"
                    size="sm"
                    variant="ghost"
                    onClick={onOpen}
                />
            ) : (
                <>
                    <Box display={'flex'} gap={4}>
                        {delivery.status >= DeliveryStatus.ON_THE_WAY && (
                            <>
                                <Button
                                    fontWeight="normal"
                                    colorScheme="blue"
                                    fill="white"
                                    leftIcon={
                                        <Icon
                                            as={Printer}
                                            fill={'blue'}
                                            cursor="pointer"
                                        />
                                    }
                                    variant="outline"
                                    onClick={() => handleReprint()}
                                    display={{
                                        base: 'none',
                                        md: 'block'
                                    }}
                                    isLoading={isLoading}
                                >
                                    Imprimir delivery
                                </Button>
                                <Button
                                    fontWeight="normal"
                                    colorScheme="blue"
                                    fill="white"
                                    onClick={() => handleReprint()}
                                    display={{
                                        base: 'block',
                                        md: 'none'
                                    }}
                                    isLoading={isLoading}
                                    variant="outline"
                                >
                                    <Icon
                                        as={Printer}
                                        fill={'blue'}
                                        cursor="pointer"
                                    />
                                </Button>
                            </>
                        )}
                        {delivery.is_paid ? (
                            <Badge
                                colorScheme="green"
                                fontWeight="normal"
                                variant="solid"
                                rounded="md"
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                px="2"
                            >
                                Pagado
                            </Badge>
                        ) : (
                            <>
                                <Button
                                    fontWeight="normal"
                                    colorScheme="blue"
                                    fill="white"
                                    title={
                                        isOpenCashbox
                                            ? ''
                                            : 'Para realizar un cobro necesita abrir CAJA con su usuario'
                                    }
                                    leftIcon={<CashIcon />}
                                    rightIcon={<ArrowRight />}
                                    onClick={() => go(delivery.order_id)}
                                    isDisabled={
                                        !isOpenCashbox || delivery.is_paid
                                    }
                                    display={{
                                        base: 'none',
                                        md: 'block'
                                    }}
                                >
                                    Realizar pago
                                </Button>
                                <Button
                                    fontWeight="normal"
                                    colorScheme="blue"
                                    fill="white"
                                    isDisabled={
                                        !isOpenCashbox || delivery.is_paid
                                    }
                                    display={{
                                        base: 'block',
                                        md: 'none'
                                    }}
                                    rightIcon={<ArrowRight />}
                                    onClick={() => go(delivery.order_id)}
                                >
                                    Pagar
                                </Button>
                            </>
                        )}
                    </Box>
                </>
            )}
        </>
    )
}
