import {
    Box,
    Flex,
    Icon,
    Radio,
    Text,
    VStack,
    FormControl,
    Button
} from '@chakra-ui/react'
import Pencil from 'components/Icon/Pencil'
import Phone from 'components/Icon/Phone'
import { useState } from 'react'
import FormInput from './FormInput'

export default function RadioLabelPhone({
    value,
    label,
    description,
    isLoading,
    submitEditHandler,
    ...props
}: RadioLabelPhoneProps) {
    const [modeEdit, setModeEdit] = useState<boolean>(false)

    const handleSubmit = async (_value: string) => {
        await submitEditHandler(_value, props.index)
        setModeEdit(false)
    }

    return (
        <>
            {modeEdit ? (
                <VStack spacing={1} alignItems={'flex-end'}>
                    <FormControl>
                        <FormInput
                            isLoading={isLoading}
                            icon={<Phone />}
                            onSubmit={handleSubmit}
                            type="number"
                            value={value}
                            placeholder="Escribir número de contacto"
                        />
                    </FormControl>
                    <Button
                        fontWeight="medium"
                        colorScheme="blue"
                        variant="link"
                        isDisabled={isLoading}
                        size="sm"
                        onClick={() => {
                            setModeEdit(false)
                        }}
                    >
                        {'Cancelar'}
                    </Button>
                </VStack>
            ) : (
                <label>
                    <Flex
                        w="full"
                        cursor="pointer"
                        borderWidth={1}
                        rounded="md"
                        px={2}
                        py={1}
                        bg="white"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Flex gap={3}>
                            <Radio name="payment" value={value} />
                            <Box>
                                <Text fontSize="sm">{label}</Text>
                                {description && (
                                    <Text
                                        fontSize="xs"
                                        color="blackAlpha.700"
                                        fontWeight="light"
                                    >
                                        {description}
                                    </Text>
                                )}
                            </Box>
                        </Flex>
                        <Flex gap={2} alignItems={'center'}>
                            <Icon
                                fill="blue.400"
                                as={Pencil}
                                cursor="pointer"
                                onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setModeEdit(true)
                                }}
                            />
                        </Flex>
                    </Flex>
                </label>
            )}
        </>
    )
}

export interface RadioLabelPhoneProps {
    value: string
    label: string
    description?: string
    submitEditHandler: (value: string, index: number) => Promise<void>
    isLoading: boolean
    index: number
}
