import { FormEvent, useEffect, useState } from 'react'
import useDelivery from '../../../../hooks/useDelivery'
import val from '../../../../lib/val'
import Client from '../../../../models/client'
import useUpdateClient from '../../hooks/useUpdateClient'
import { AddressFormData } from './types'
import AddressModalForm from './AddressModalForm'

interface Props {
    isOpen: boolean
    handleClose: () => void
    index: number | null
}

export default function EditAddressFormModal({
    isOpen,
    handleClose,
    ...props
}: Props) {
    const { submit, isLoading } = useUpdateClient()
    const client = useDelivery().client as Client
    const [helpers, setHelpers] = useState<Partial<AddressFormData>>({})
    const [form, setForm] = useState<AddressFormData>({
        reference: '',
        address: ''
    })

    useEffect(() => {
        if (props.index === null) return

        const [address, reference] =
            props.index !== null ? client.addresses?.[props.index] : ['', '']

        setForm({
            address,
            reference
        })
    }, [props.index])

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target
        setForm(prevValues => ({
            ...prevValues,
            [name]: value
        }))
    }

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()

        const v = val<AddressFormData>(form)
        v.address.isEmpty('La dirección es requerida')
        const result = v.runtest()
        if (result) {
            setHelpers(result)
            return
        }

        const addresses: [string, string][] = []
        for (let index = 0; index < client.addresses?.length; index++) {
            const element = client.addresses[index]
            if (index === props.index) {
                addresses.push([form.address, form.reference])
            } else {
                addresses.push(element)
            }
        }

        await submit({
            addresses: [...addresses]
        })

        handleClose()
    }

    return (
        <>
            <AddressModalForm
                isOpen={isOpen}
                handleClose={() => handleClose()}
                helpers={helpers}
                isLoading={isLoading}
                handleSubmit={submitHandler}
                address={form.address}
                reference={form.reference}
                isEdit
                handleInputChange={handleInputChange}
            />
        </>
    )
}
